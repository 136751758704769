import React, { useEffect, useState, useCallback } from 'react';
import { Link } from 'react-router-dom';
import './ServicesPage.css';
import { FaPhone, FaWhatsapp } from 'react-icons/fa';
import axios from "axios";

const ServicePage = () => {
  const [services, setServices] = useState([]);
  const [location, setLocation] = useState('');
  const [category, setCategory] = useState('');
  const [filteredServices, setFilteredServices] = useState([]);
  const [city, setCity] = useState('');

   const categories = [
    "Real Estate", "Electrician", "Train Ticket",
    "AC Repair","Clear"
  ];

  const handleCategoryClick = (category) => {
    if(category === "Clear"){
      setCategory("")
    }else{
      setCategory(category);
    }
  };

  const getLocationPermission = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          getCityName(latitude, longitude).then((cityName) => {
            setCity(cityName);
            setLocation(cityName);
            localStorage.setItem("city", cityName);
          });
        },
        (error) => {
          console.error("Error getting location:", error);
          alert("Location access is required for better service recommendations.");
        }
      );
    } else {
      alert("Geolocation is not supported by this browser.");
    }
  };

  const getCityName = async (latitude, longitude) => {
    const url = `https://nominatim.openstreetmap.org/reverse?format=json&lat=${latitude}&lon=${longitude}&addressdetails=1`;

    try {
      const response = await axios.get(url);
      const address = response.data.address;

      if (address && address.city) {
        return address.city;
      } else if (address && address.town) {
        return address.town;
      } else if (address && address.village) {
        return address.village;
      } else {
        return 'City not found';
      }
    } catch (error) {
      console.error('Error fetching city name:', error);
      return 'Error fetching city name';
    }
  };

  const handleClearLocation = () => {
    localStorage.removeItem("city");
    setLocation('');
    setCity('');
  };

  useEffect(() => {
    axios.get("https://sabreserve.com/services/all")
      .then((res) => {
        setServices(res.data);
        setFilteredServices(res.data);
      })
      .catch((err) => {
        console.log("err", err);
      });
  }, []);

  const filterServices = useCallback(() => {
    let filtered = services;

    function normalizeString(str) {
      return str.toLowerCase().replace(/\s+/g, '').replace(/[^a-zA-Z0-9]/g, '');
    }

    if (location) {
      const normalizedLocation = normalizeString(location);
      filtered = filtered.filter(service =>
        service.combinedAddress &&
        normalizeString(service.combinedAddress).includes(normalizedLocation)
      );
    }

    if (category) {
      const normalizedCategory = normalizeString(category);
      filtered = filtered.filter(service =>
        normalizeString(service.title).includes(normalizedCategory)
      );
    }

    setFilteredServices(filtered);
  }, [location, category, services]);

  useEffect(() => {
    filterServices();
  }, [location, category, services, filterServices]);

  const formatAMPM = (date) => {
    let hours = date.getHours();
    let minutes = date.getMinutes();
    const ampm = hours >= 12 ? 'PM' : 'AM';
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? '0' + minutes : minutes;
    const strTime = hours + ':' + minutes + ' ' + ampm;
    return strTime;
  };

  const handleAddBooking = (category, serviceProvider, providerWhatsapp, providerContact) => {
    const currentDate = new Date();
    const formattedTime = formatAMPM(currentDate);
    let userPhoneNumber = localStorage.getItem("userPhoneNumber") || "";

    let payload = {
      category,
      serviceProvider,
      providerWhatsapp,
      providerContact,
      phone:userPhoneNumber,
      bookingTime: currentDate.toLocaleDateString() + ' ' + formattedTime,
      contacted: false
    };

    axios.post("https://sabreserve.com/bookings/add", payload)
      .then((res) => {
        console.log('res', res);
      })
      .catch((err) => {
        console.log("err", err);
      });
  };

  return (
    <div className="services-page">
      <h2>Our Services</h2>
      <div className="category-tags">
        {categories.map((cat, index) => (
       <button
       key={index}
       className={`category-tag ${category === cat ? 'active' : ''} ${cat === 'Clear' ? 'clear-tag' : ''}`}
       onClick={() => handleCategoryClick(cat)}
     >
       {cat}
     </button>
     
        ))}
      </div>

      <div className="filter-section">
        <input
          type="text"
          placeholder="Search by location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
        />
        <input
          type="text"
          placeholder="Search by category"
          value={category}
          onChange={(e) => setCategory(e.target.value)}
        />
        <button className="location-button" onClick={getLocationPermission}>
          Select Location
        </button>
        <button className="clear-button" onClick={handleClearLocation}>
          Clear Location
        </button>
      </div>

      {!location && (
        <div className="no-location">
          <p>Please search or select a location.</p>
        </div>
      )}

      <div className="services-list">
        {/* {location && filteredServices.map(service => ( add in future */}
        {filteredServices.map(service => (
          <div className="service-item" key={service._id}>
            <img src={service.image} alt={service.title} />
            <h3>{service.title}</h3>
            <p>{service.description}</p>
            <div className="buttons">
              <div
                onClick={() => handleAddBooking(service.category, service.serviceProvider, service.whatsappNumber, service.contactNumber)}
                className="call-button"
              >
                <a href={`tel:${service.contactNumber}`}>
                  <FaPhone /> Call
                </a>
              </div>
              <div
                onClick={() => handleAddBooking(service.category, service.serviceProvider, service.whatsappNumber, service.contactNumber)}
                className="whatsapp-button"
              >
                <a href={`https://wa.me/${service.whatsappNumber}`}>
                  <FaWhatsapp /> WhatsApp
                </a>
              </div>
            </div>
            <Link to={`/services/details/${service._id}`} className="view-details">View Details</Link>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServicePage;
