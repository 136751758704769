import React, { useState, useEffect } from 'react';
import './PhoneNumberModal.css';

const PhoneNumberModal = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState('');

  useEffect(() => {
    const storedPhoneNumber = localStorage.getItem('userPhoneNumber');
    if (!storedPhoneNumber) {
      setIsModalOpen(true);
    }
  }, []);

  const handleSavePhoneNumber = () => {
    if (phoneNumber) {
      localStorage.setItem('userPhoneNumber', phoneNumber);
      setIsModalOpen(false);
    } else {
      alert('Please enter a valid phone number.');
    }
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      {isModalOpen && (
        <div className="modal-backdrop">
          <div className="modal">
            <h3>Enter Your Phone Number</h3>
            <input
              type="tel"
              placeholder="Enter phone number"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
            />
            <div className="modal-actions">
              <button onClick={handleSavePhoneNumber} className="save-button">Save</button>
              <button onClick={handleCloseModal} className="close-button">Close</button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default PhoneNumberModal;
